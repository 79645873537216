<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','address']"
            :form_data_seo="form_data_seo"
            :submit_preprocessing="submit_preprocessing"
            ref="list"
        >
            <template
                slot="address"
                slot-scope="data"
            >{{data.record.province + data.record.city + data.record.district }}</template>
            <template slot="operation" slot-scope="data">
                <a @click="get_map(data.record)">查看</a>
            </template>
        </TableList>
        <a-modal :visible="visible" :destroyOnClose="true" @ok="submit" @cancel="handleCancel" width="800px" title="地图">
			<Form @handleChange="form_map_change" :form_data="form_data_map"></Form>
            <VisitMap :points="points" :center="center" style="height:500px"></VisitMap>
        </a-modal>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { historyTrack, todayTrack, locusMap} from "@/api/locus";
import TableList from "@/components/TableList";
import VisitMap from "@/components/VisitMap";


const columns = [
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "岗位职务",
        dataIndex: "role_name",
    },
    {
        title: "账号",
        dataIndex: "phone",
    },
    {
        title: "地区",
        dataIndex: "address",
        scopedSlots: {
            customRender: "address"
        }
    },
    {
        title: "上报次数",
        dataIndex: "num",
    },
    {
        title: "时间",
        dataIndex: "visit_time",
    },
    {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: {
            customRender: "operation"
        }
    },
];


export default {
    name: "Index",
    components: {
        EditPop,
        TableList,
        Form,
        VisitMap
    },
    data() {
        return {
            get_table_list: historyTrack,
            submit_preprocessing: {
                array_to_string: ['department']
            },
            config: this.$config,
            center: { lng: 115.86631, lat: 28.695285 },
            points: [],
            status_list: this.$config.status_list,
			columns,
			record:{},
			visible: false,
			form_data_map:{
				layout: "inline",
				list:[
					{
                        type: 'radio',
                        mode: 'button',
                        name: 'times',
                        title: '',
                        options: {
                            initialValue: 1
                        },
                        list: [
                            {
                                key: 1,
                                value: '今日轨迹'
                            },
                            {
                                key: 7,
                                value: '7日轨迹'
                            },
                            {
                                key: 30,
                                value: '30日轨迹'
                            },

                        ]
                    },
				]
			},
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "select",
                        name: "role",
                        title: "岗位职务",
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						mode: "default",
						options: {},
						list: this.$config.user_status_list
					},
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "登录时间",
                        options: {
                        	initialValue: [this.$moment(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)), 'YYYY/MM/DD'), this.$moment(new Date(), 'YYYY/MM/DD')],
                        },
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
            },
            form_data: {

			},
			time:30,
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
        });
        this.$method.get_role().then(res => {
            this.form_data_seo.list.forEach(item => {
                if (item.name == "role") {
                    item.list = res;
                }
            });
        });
    },
    methods: {
        get_map(record, data = {}) {
			this.record = record;
            locusMap({
                data: {
					id: record.id,
					visit_time:record.visit_time,
					...data,
                }
            }).then(res => {
				this.visible = true;
                this.points = res.data.list.map((item, index) => {
                    if (index == 0) {
                        this.center = { lng: item.longitude, lat: item.latitude };
					}
					
                    return {
                        lng: item.longitude,
                        lat: item.latitude,
                        ...item,
                    }
                })
            })
		},
		form_map_change({e}){
			try {
				let times = e.target.value
				this.get_map(this.record,{
					times,
				})
			}catch(e){

			}
		},
        
        submit() {
            this.visible = false;
        },
        handleCancel() {
            this.visible = false;
        }
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>